.chart_page{

    .chart-box{
        .apexcharts-text,.apexcharts-yaxis-label {
            // fill: white !important;
            opacity: 0.8;
        }
    }
    .date_buttons{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }
}