.company{
    hr{
        margin: 5px auto;
        padding: 0%;
    }
    .company_details{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .company_name{
            p{
                display: flex;
                align-items: center;
                justify-content: left;
                margin-bottom: 0%;
                font-size: smaller;
            }
            .lower_p{
                margin-top: 2px;
                font-size: 12px;
                color: rgb(115 115 115);
          

            }
        }
        .company_price{
            p{
                display: flex;
                justify-content: end;
                margin-bottom: 0%;
                font-size: smaller;
                color: rgb(48 39 169);
            }
            .lower_p{
                margin-top: 2px;
                font-size: 12px;
                color: rgb(115 115 115);
          


            }
        }
        .company_buttons{
            cursor: pointer;
            color: rgb(58, 58, 58);
        }

    }
}