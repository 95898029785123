.option-chain-table{
    width: 100%;
    // color: rgb(209, 209, 209);
  overflow-y: auto; 

    .tables-container{
        display: flex;
        align-items: center;
        justify-content: left;
        .table-responsive{
            padding: 0%;
            table{
                margin-bottom: 0;
            }
            overflow: auto;
            th, td{

                font-size: small;
                // padding: 1px;
            }
            thead{
                // position: fixed;
            }
            td{
                font-size: smaller;
            }
        }
        .table-responsive::-webkit-scrollbar {

  width: 5px;
  color: black;

}
        .strike{
            border: none;
            // thead{
            //     border: none;
            //     th{
            //         border: none;
            //     }
            // }
            
        }
    }
     
}