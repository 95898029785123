.companies {
  box-shadow: -10px -10px 15px -5px rgba(0, 0, 0, 0.2),
              -10px 10px 15px -5px rgba(0, 0, 0, 0.2), 
              -10px 0px 15px -5px rgba(0, 0, 0, 0.2);
  height: 100vh;
  overflow-y: auto;
  // background-color: rgb(0, 0, 0);
  // color: white;

  .row {
    margin: 0;
  }

  .index_funds {
    padding: 10px 0%;

    .index_funds_item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .company_name {
        p {
          display: flex;
          align-items: center;
          justify-content: left;
          margin-bottom: 0%;
          font-size: 11px;
          // color: rgb(78, 72, 161);

        }
        .lower_p {
          margin-top: 2px;
          font-size: 10px;
          // color: rgb(78, 72, 161);

          color: rgb(115 115 115);
          
        }
      }
      .company_price {
        p {
          display: flex;
          justify-content: end;
          margin-bottom: 0%;
          font-size: smaller;
          color: rgb(48 39 169);
          font-size: 11px;
        }
        .lower_p {
          margin-top: 2px;
          font-size: 10px;
          // color: rgb(78, 72, 161);

          color: rgb(115 115 115);
          

        }
      }
      .company_buttons{
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;
        color: rgb(58, 58, 58);
      }
    }
    .sensex{
        border-left: solid 1px rgb(51, 51, 51);

    }
  }

  .input_div {
    width: 93%;
    margin: 0 auto;
  }

  hr {
    margin-bottom: 3px;
  }

  .companies-list {
    padding: 10px;
  }
}

.companies::-webkit-scrollbar {
  width: 2px;
  color: black;
}

.companies::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  color: black;
}

.companies::-webkit-scrollbar-thumb {
  color: black;
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
