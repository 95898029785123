.quote_panel {
  // background-color: rgb(0, 0, 0);
  box-shadow: -10px 0px 15px -5px rgba(0, 0, 0, 0.2);
  width: 200px;
// background-color: aqua;
  // color: white;
  margin-left: 10px;
  padding: 10px 10px;
  .quote_heading {
    display: flex;
    align-items: center;
    gap: 20px;
    span {
      cursor: pointer;
    padding-bottom: 15px;

    }
    
  }
  .company_details {
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .company_name {
      p {
        display: flex;
        align-items: center;
        justify-content: left;
        margin-bottom: 0%;
        font-size: smaller;
      }
      .lower_p {
        margin-top: 2px;
          
          font-size: 12px;
        // color: rgb(115 115 115);
      }
    }
    .company_price {
      p {
        margin-bottom: 0%;
        font-size: smaller;
        color: rgb(48 39 169);
      }
      .lower_p {
        display: flex;
        justify-content: end;
        margin-top: 2px;
        font-size: 12px;
          
          // color: rgb(115 115 115);
      }
    }
  }
  span{
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 12px;
    padding-bottom: 10px;
  }
  .market_stats {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .quote {
      .quote_details {
        p {
        margin-bottom: 0%;

          display: flex;
          align-items: center;
          justify-content: left;
        font-size: smaller;

        }
        .upper_p {
        margin-top: 2px;
          font-size: 10px;
          
          color: rgb(115 115 115);
        }
      }
    }
  }
  .market_depth{
      th{
          text-wrap: nowrap;
          font-size: 10px;
        }
        td{
            font-size: smaller;
        }
  }
}
