.home-container {
  display: flex;

  .companies-container {
    width: 24%;
    height: 100vh;
    overflow-y: auto;
    // box-shadow: -10px 0px 15px -5px rgba(0, 0, 0, 0.2);
  }

  .chart-container {
    box-shadow: -8px 0px 10px -5px rgba(0, 0, 0, 0.2);
    width: 75%;
    .chart_home {
      display: flex;
      align-items: center;
      // justify-content: space-between;
      .chart_section {
        
        width: 100%;
        overflow-y: auto;
        height: 100vh;
        .logo_section {
          display: flex;
          align-items: center;
          padding-right: 15px;
          .logo {
            margin: 20px auto;
            img {
              cursor: pointer;
              width: 150px;
              border-radius: 10px;
            }
          }
          .btn_section {
            display: flex;
            align-items: center;
            gap: 10px;
          }
        }
      }
      .quote_section {
      }

      .chart_section::-webkit-scrollbar {
        width: 5px;
        color: black;
      }
    }
  }
}
